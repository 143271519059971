import {
  LOGGED_IN,
  USER_DATA,
  FORGOT_PASSWORD_POPUP,
  LOGGED_IN_POPUP,
  REGISTER_POPUP,
  TAXI_DATA,
  BOOKING_SUMMARY_DATA,
  ADD_ITEM_TO_FOOD_CART,
  REMOVE_ITEM_FROM_FOOD_CART,
  DISCARD_FOOD_ITEM,
  USER_LOCATION,
  EMPTY_FOOD_CART,
  ADD_ITEM_TO_GROCERY_CART,
  REMOVE_ITEM_FROM_GROCERY_CART,
  DISCARD_GROCERY_ITEM,
  EMPTY_GROCERY_CART,
  SERVICE_SEGMENTS,
  ADD_ITEM_TO_PLUMBING_CART,
  REMOVE_ITEM_FROM_PLUMBING_CART,
  DISCARD_PLUMBING_ITEM,
  CONFIGURATION_DATA,
  CONFIG_DATA,
  SITE_DATA,
  EMPTY_PLUMBING_CART,
  SEGMENT_ERR_MESSAGE,
  FOOD_CART_ADDED_ID,
  FOOD_CART_REMOVE_ADDED_ID,
  GLOBAL_LOADING,
} from "../constants";

// Action Creator
export const userData = (data) => ({
  type: USER_DATA,
  data: data,
});

export const userLoggedIn = (data) => ({
  type: LOGGED_IN,
  data: data,
});

export const setShowLoggedInPopup = (data) => ({
  type: LOGGED_IN_POPUP,
  data: data,
});

// export const showForgotPasswordPopup = (data) => ({
//   type: FORGOT_PASSWORD_POPUP,
//   data: data,
// });

export const setShowRegisterPopup = (data) => ({
  type: REGISTER_POPUP,
  data: data,
});

export const setUserLocation = (data) => ({
  type: USER_LOCATION,
  data: data,
});

export const setServiceSegments = (data) => ({
  type: SERVICE_SEGMENTS,
  data: data,
});

export const setConfigData = (data) => ({
  type: CONFIG_DATA,
  data: data,
});

export const setSiteData = (data) => ({
  type: SITE_DATA,
  data: data,
});

export const setGlobalLoading = (data) => ({
  type: GLOBAL_LOADING,
  data: data,
});

// Taxi segment
export const setTaxiServiceData = (data) => ({
  type: TAXI_DATA,
  data: data,
});

export const setBookingData = (data) => ({
  type: BOOKING_SUMMARY_DATA,
  data: data,
});

// Food segment

export const addItemToFoodCart = (data) => {
  return {
    type: ADD_ITEM_TO_FOOD_CART,
    data: data,
  };
};
export const removeItemFromFoodCart = (data) => {
  return {
    type: REMOVE_ITEM_FROM_FOOD_CART,
    data: data,
  };
};
export const discardFoodItem = (data) => {
  return {
    type: DISCARD_FOOD_ITEM,
    data: data,
  };
};

export const setEmptyFoodCart = (data) => {
  return {
    type: EMPTY_FOOD_CART,
    data: data,
  };
};

export const setFoodCartAddedId = (data) => {
  return {
    type: FOOD_CART_ADDED_ID,
    data: data,
  };
};

export const setFoodCartRemoveAddedId = (data) => {
  return {
    type: FOOD_CART_REMOVE_ADDED_ID,
    data: data,
  };
};

// Grocery segment

export const addItemToGroceryCart = (data) => {
  return {
    type: ADD_ITEM_TO_GROCERY_CART,
    data: data,
  };
};

export const removeItemFromGroceryCart = (data) => {
  return {
    type: REMOVE_ITEM_FROM_GROCERY_CART,
    data: data,
  };
};

export const discardGroceryItem = (data) => {
  return {
    type: DISCARD_GROCERY_ITEM,
    data: data,
  };
};

export const setEmptyGroceryCart = (data) => {
  return {
    type: EMPTY_GROCERY_CART,
    data: data,
  };
};

// Plumbing segment

export const addItemToPlumbingCart = (data) => {
  return {
    type: ADD_ITEM_TO_PLUMBING_CART,
    data: data,
  };
};

export const removeItemFromPlumbingCart = (data) => {
  return {
    type: REMOVE_ITEM_FROM_PLUMBING_CART,
    data: data,
  };
};

export const discardPlumbingItem = (data) => {
  return {
    type: DISCARD_PLUMBING_ITEM,
    data: data,
  };
};

export const setEmptyPlumberCart = (data) => {
  return {
    type: EMPTY_PLUMBING_CART,
    data: data,
  };
};

export const setSegmentErrMessage = (data) => {
  return {
    type: SEGMENT_ERR_MESSAGE,
    data: data,
  };
};
